/* @font-face {
  font-family: 'LEMON-MILK';
  src: url(../public/assets/fonts/LEMONMILK-Regular.otf);
}

@font-face {
  font-family: 'Century-Gothic';
  src: url(../public/assets/fonts/CenturyGothic.ttf);
} */

#root {
  height: 100%;
}


html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  height: 100%;
}


body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  background: linear-gradient(180deg, #EEF5F8 17.68%, #EEF5F8 100%);
  overflow-x: hidden;
  color: #2B1D11;

}

.listed-link {
  display: inline-block;
  transition: all 0.25s linear;
}

.listed-link:hover {
  transform: translateY(-10px);
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: inherit;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}


.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.light-text {
  color: #6D6C69 !important;
}

.dark-text {
  color: #2B1D11 !important;
}

.primary-text {
  color: #FD5C46 !important;
}

.fs-14 {
  font-size: 14px !important;
}

small {
  color: #70EC9D;
}

.lh {
  line-height: normal;
}

p {
  color: #6D6C69;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

h2 {
  color: #2B1D11;
  font-size: 42px;
  font-weight: 500;
}

.br-30 {
  border-radius: 30px;
}

.br-10 {
  border-radius: 10px;
}

.hy-40 {
  max-height: 40px;
}

/* Header */

.navbar {
  /* height: 85px; */
  position: fixed;
  width: 100%;
  z-index: 111;
  top: 0px;
}

.nav-bg {
  background: linear-gradient(90deg, rgb(255 255 255 / 0%), rgba(255, 255, 255, .3));
  padding: 10px 15px;
  border-bottom: 2px solid #DFDAD6;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  max-width: 1300px !important;
}

.social-ic {
  display: inline-block;
  transition: all .2s ease-in-out;
}

.social-ic:hover {
  transform: translate(0, -5px);
  transition: all .2s ease-in-out;
}

.bg-pink {
  /* background: #fff7f272; */
  backdrop-filter: blur(10px);
}

.navbar .nav-link {
  font-weight: 400;
  font-size: 14px;
  color: #2B1D11;
  margin: 0 4px;
  position: relative;
  transition: all .2s ease-in-out;
  text-align: center;
}

.navbar .nav-link:hover {
  color: #FD5C46 !important;
  transition: all .2s ease-in-out;
}


.navbar-nav {
  position: relative;
}

/* .navbar-nav::after {
  content: "";
  background: #2AE8DC;
  height: 2px;
  width: 100%;
  bottom: -5px;
  left: 0;
  position: absolute;
} */

.navbar-collapse.collapsing {
  transition: none !important;
}


/* Banner */
.banner {
  background: url(../public/assets/bannerBG.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  padding-top: 100px;
}

.banner h1 {
  font-size: 46px;
  font-weight: 500;
  color: #2B1D11;
}

.banner p {
  color: #2B1D11;
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
}


.primary-btn {
  color: #fff;
  border-radius: 10px;
  background: #FD6746;
  border: 1px solid #FD6746;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  padding: 5px 25px;
  height: 46px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease-in-out;
}


.primary-btn:hover {
  background: transparent;
  color: #FD6746;
  transition: all .3s ease-in-out;

}

.primary-btn-outline {
  border-radius: 10px;
  background: transparent;
  color: #FD6746;
  border: 2px solid #FD6746;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  padding: 5px 25px;
  height: 46px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease-in-out;
}

.primary-btn-outline:hover {
  background: #FD6746;
  color: #fff;
  transition: all .3s ease-in-out;

}


.z-1 {
  z-index: 1;
}




@media (max-width: 992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 950px;
  }

  .navbar-toggler {
    color: #fff;
    font-size: 36px;
    border: none;
    background: none;
  }

  .navbar {
    position: fixed;
    z-index: 1111;
    left: 0;
    top: 0px;
    height: auto;
    width: 100%;
  }


  .navbar-collapse {
    position: fixed;
    left: -400px;
    opacity: 0;
    transition: all .3s linear;
    display: block !important;
  }

  .home-sm .navbar-collapse {
    position: fixed;
    top: 0;
    opacity: 1;
    width: 100%;
    left: 0;
    height: 100vh;
    background: linear-gradient(180deg, rgba(34, 20, 59, 1), rgba(34, 20, 59, 1));
    backdrop-filter: blur(10px);
    z-index: 111;
    left: 0px;
    transition: all .3s linear;
    display: block !important;
  }

  .cross-btn {
    font-size: 28px;
    color: #FD6746 !important;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-nav {
    align-items: flex-start !important;
  }

  .navbar-brand img {
    height: 30px;
  }

  /* .navbar-nav {
    gap: 20px;
    flex-direction: row;
    margin-top: 25px;
  } */

  .navbar-nav::after {
    content: none;
  }



}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1120px;
  }
}


h3 {
  color: #2B1D11;
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 0;
}

h4 {
  color: #2B1D11;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0;
}


.lp {
  position: relative;
}

.lp::before {
  content: "";
  position: absolute;
  left: -30px;
  height: 100%;
  width: 1px;
  background: linear-gradient(106deg, #ffffff -34.07%, rgba(0, 0, 0, 0.23) 102.68%);
}


.partner-list h3 {
  font-size: 26px;
  line-height: 70px;
  color: #FD5C46;
  white-space: nowrap;
}

.col-20 {
  /* width: calc(100% / 6); */
  padding: 0 12px;
}

#features h4 {
  color: #FD5C46;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 20px;
}

#features p {
  margin-bottom: 0;
}

.bg-3 {
  background-image: url(../public/assets/FooterBg.png);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}

.featured {
  background: url(../public/assets/featuredBG.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 50px 0;
}

.feature-card {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  gap: 20px;
  transition: all .3s ease-in-out;
}

.feature-img {
  max-height: 200px;
}

.feature-img img {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.feature-card:hover img {
  filter: drop-shadow(0.5em 0.5em 0.8em #FFCCD5);
  transition: all .3s ease-in-out;
}

.feature-detail {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  gap: 10px;
  padding-left: 15px;
}

.feature-card p {
  line-height: 20px;
  margin-bottom: 0;
  font-size: 13px;
}

.feature-card h3 {
  font-size: 18px;
  font-weight: 600;
  color: #2B1D11;
  line-height: 30px;
  margin-bottom: 25px;
}

.feature-bg {
  background: url(../public/assets/bg1.png);
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
}


.metrics-box {
  border: 1px solid rgba(255, 255, 255, .7);
  border-radius: 30px;
  padding: 30px;
}


.metrics-box p {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: white;
  margin-bottom: 0;
}

.metric-box {
  padding: 5px;
}


.metric-box h4 {
  font-size: 24px;
  line-height: 34px;
  color: #2B1D11;
  margin-bottom: 5px;
}

.metric-box p {
  margin-bottom: 0;
}

.tab-content {
  min-height: 120px;
}

.nav-pills .nav-link {
  text-align: start;
  border-radius: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: all .2s ease-in-out;
  border-left: 3px solid rgba(126, 176, 251, 0);
}

.nav-pills .nav-link.active {
  border-left: 3px solid rgba(126, 176, 251, .5);
  background: white;
  transition: all .2s ease-in-out;
}

.sky-card {
  height: 100%;
  border-radius: 10px;
  background: #FFF;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(126, 176, 251, .5);
  justify-content: space-between;
  transition: all .2s ease-in-out;
}

/* .sky-card:hover {
  background-color: #ffd5aa63;
  transition: all .2s ease-in-out;
} */

.sky-detail {
  padding: 30px;
}

.sky-detail h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 20px;
}

.sky-detail p {
  margin-bottom: 0px !important;
}


.sky-card .img-box {
  max-height: 330px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.sky-card .img-box img {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.max-350 {
  max-width: 350px;
}

.user-box {
  background: rgba(255, 255, 255, .4);
  border-radius: 10px;
  padding: 20px;
  height: 100%;
  border: 1px solid rgba(126, 176, 251, .5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.user-box p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.user-box a {
  color: #2698E1;
}

.user {
  display: flex;
  /* align-items: center; */
  gap: 15px;
}

.user-detail {
  flex: 1;
}

.user-detail h6 {
  font-weight: 600;
  color: #2B1D11;
  font-size: 16px;
}

.user-detail p {
  font-weight: 400;
  color: #6D6C69;
  font-size: 14px;
}

.user-img {
  width: 42px;
  height: 42px;
  border-radius: 50px;
}

.user-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50px;
}

.box {
  background: rgba(255, 255, 255, .4);
  border: 1px solid rgba(126, 176, 251, .5);
  border-radius: 10px;
  backdrop-filter: blur(20px);
  padding: 20px;
}



footer {
  padding-top: 50px;
}

.footer-link {
  font-size: 14px;
  font-weight: 400;
  color: #6D6C69;
  line-height: 20px;
  display: block;
  transition: all .2s ease-in-out;
}

.bt-1 {
  border-top: 1px solid #2B1D11;
}

.footer-link:hover {
  color: #FD5C46 !important;
  transition: all .2s ease-in-out;
}

.footer-ic {
  display: inline-block;
  transition: all .2s ease-in-out;
}

.footer-ic:hover {
  transform: translate(0, -5px);
  transition: all .2s ease-in-out;
}


/* faqs css  */

.faq .accordion-item:first-of-type .accordion-button {
  background-color: transparent !important;
  color: #2B1D11;
  border: 0;
  padding-right: 80px;
}

/* .faq .accordion-button:not(.collapsed)::after {
  background-image: url(../public/assets/-.svg);
  transform: rotate(-180deg);
} */

.faq .accordion-button:not(.collapsed)::before {
  background: transparent;
}

/* .faq .accordion-button::before {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  position: absolute;
  content: "";
  right: 3px;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-size: 2rem;
  transition: transform .2s ease-in-out;
  z-index: 0;
} */

.faq .accordion-button::before :hover {
  background: transparent;
}

.faq .accordion-button::after {
  margin-left: 10px;
  /* flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-left: auto;
  content: "";
  background-image: url(../public/assets/+.svg);
  background-repeat: no-repeat;
  background-size: 2rem;
  transition: transform .2s ease-in-out;
  z-index: 9;
  position: absolute;
  right: 20px; */
}

.faq .accordion-button::after:hover {
  background-color: red;
}

.faq .accordion-item {
  background-color: transparent;
  border-radius: 0px;
  border: 0;
  border-bottom: 2px solid #6D6C69;
  padding: 0px 0;
  margin-bottom: 10px;
}

.faq .accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.faq .accordion-button {
  border-color: transparent;
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  text-align: left;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #2B1D11;
  background-color: transparent;
  padding: 10px 60px 10px 0px !important;
}


.faq .accordion-body {
  font-size: 17px;
  font-weight: 300;
  line-height: 23px;
  color: #6D6C69;
  max-width: 1100px;
  padding: 0;
  padding-bottom: 20px;
}

.faq .accordion-body a {
  color: #06F4FF;
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
}

.faq ul {
  padding-left: 20px;
}

.faq ul li {
  list-style-type: disc;
}

/* faq end  */

.audit {
  height: 60px;
}

/* .partner-list{
  overflow-x: auto;
  overflow-y: hidden;
}

.scroll-x{
    min-width: 1100px;
} */


.btn-1 {
  transition: all .2s ease-in-out;
}

.bx-1 {
  border-left: 1px solid #B6D3FA;
  border-right: 0px solid #B6D3FA;
}

.btn-1:hover {
  background: #FD6746;
  color: white;
  transform: translate(0, -5px);
  transition: all .2s ease-in-out;
}


.partner-list::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.partner-list::-webkit-scrollbar-track {
  background: #3d3d3d;
}

.partner-list::-webkit-scrollbar-thumb {
  background: #252525;
}

.partner-list .col-2 {
  padding: 0 12px;
}

/* .scroll-x .col-20{
    width: calc(100% / 6);
    padding: 0 12px;
} */

.w-sm {
  width: 100%;
}

.logo-1 {
  height: 30px;
}

.logo-2 {
  height: 65px;
}

.logo-3 {
  height: 47px;
}

.logo-4 {
  height: 32px;
}

.logo-5 {
  height: 40px;
}
.logo-8 {
  height: 60px;
}
.logo-10{
  height: 42px;
}

@media(max-width: 1200px) {
  .logo-1 {
    height: 28px;
  }

  .logo-2 {
    height: 60px;
  }

  /* .logo-3 {
    height: 36px;
  } */

  .logo-4 {
    height: 30px;
  }

  .logo-5 {
    height: 36px;
  }

}

@media(max-width: 960px) {
  .logo-1 {
    height: 28px;
  }

  .logo-2 {
    height: 60px;
  }
/* 
  .logo-3 {
    height: 33px;
  } */

  .logo-4 {
    height: 28px;
  }

  .logo-5 {
    height: 30px;
  }
}



@media(max-width: 767px) {

  .logo-1 {
    height: 26px;
  }

  .logo-2 {
    height: 65px;
  }

  .logo-3 {
    height: 50px;
  }

  .logo-4 {
    height: 26px;
  }

  .logo-5 {
    height: 30px;
  }

  .logo-6 {
    height: 32px;
  }

  .logo-7 {
    height: 80px;
  }

  .logo-8 {
    height: 50px;
  }

  .navbar .nav-link {
    color: #fff;
  }

  .banner {
    background-size: 170%;
    padding-top: 90px;
    background-position: top right;
  }

  .banner h1 {
    font-size: 42px;
    margin-top: 0px;
    margin-bottom: 0;
  }

.w-5{
  width: 50%;
}
  .banner p {
    font-size: 18px;
    line-height: 28px;
  }

  /* .partner-list .col-20 {
    width: calc(100% / 2);
    padding: 0 15px;
  } */

  /* .partner-list .col-20 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  } */

  .w-sm-100 {
    width: 100%;
    height: auto !important;
  }

  .partner-list .col-20 {
    margin-bottom: 10px;
  }

  h2 {
    font-size: 36px;
    font-weight: 600;
  }

  .metrics-box h4 {
    font-size: 42px;
    line-height: 42px;
  }

  .bx-1 {
    border-left: 0px solid #B6D3FA;
    border-right: 0px solid #B6D3FA;
    border-top: 1px solid #B6D3FA;
    border-bottom: 0px solid #B6D3FA;
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .metrics-box p {
    font-size: 18px;
    line-height: 24px;
  }


  .bg-box {
    padding: 30px 20px;
    border-radius: 20px;
  }

  .bg-box p {
    font-size: 16px;
    line-height: 26px;

  }

  .nav-pills .nav-link {
    padding: 5px;
  }

  h4 {
    font-size: 22px;
    line-height: 34px !important;
    margin-bottom: 10px;
  }

  .faq .accordion-body {
    padding-left: 0;
    padding-top: 0;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }


  .fs-22 {
    font-size: 18px !important;
    line-height: 28px;
    padding: 0 20px;
  }

  .nova-card p {
    font-size: 16px;
  }

  .nova-logo {
    height: 60px;
  }

  h3 {
    font-size: 26px !important;
  }

  .nova-card {
    min-height: 280px;
  }

  .navbar-brand img {
    height: 40px;
  }

  footer {
    padding: 25px 0px 0 0;
  }

  .footer-link {
    margin-bottom: 4px;
    font-weight: 300;
  }

  .faq .accordion-button {
    font-size: 16px;
    line-height: 20px;
    padding: 15px 40px 15px 0px !important;
  }

  .faq .accordion-body {
    font-size: 14px;
    line-height: 22px;
  }

  .tab-content {
    min-height: auto;
  }

  .faq .accordion-item {
    padding: 5px 0px 5px 0px;
    border-radius: 00px;
  }

  .scroll-list {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .scroll-list::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .scroll-list::-webkit-scrollbar-track {
    background: #3d3d3d;
  }

  .scroll-list::-webkit-scrollbar-thumb {
    background: #252525;
  }

}
